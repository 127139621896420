import "./App.css";
import Home from "./views/pages/Home";
import StakeTrackers from "./views/pages/StakeTrackers";
import Dashboards from "./views/pages/Dashboards";
import NetworkExplorers from "./views/pages/NetworkExplorers";
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom' 


function App() {
  return (
    <div className="App">
     <Router>
     <Switch>
     <Route path="/StakeTrackers" exact>
         <StakeTrackers/>
       </Route>
       <Route path="/Dashboards" exact>
         <Dashboards/>
       </Route>
       <Route path="/NetworkExplorers" exact>
         <NetworkExplorers/>
       </Route>
       <Route path="/" exact>
         <Home/>
       </Route>
       <Route>
       <Redirect to="/404" />
          <Home/>
       </Route>
       
       {/*<Route path="*" component={NotFoundPage} />
       </Route>*/}
      
     </Switch>
     </Router>

    </div>
  );
}

export default App;
