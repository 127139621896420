
import React, { Component, PropTypes, useState } from 'react'
import '../../../assets/css/Body.css'
import { Link } from 'react-router-dom';

function Body() {
 
  

  return (
    <div className="home-body">
 </div>

 
  );
}

export default Body; 



