import React, { Component, PropTypes, useState } from "react";
import "../../../assets/css/Body.css";
import radixportfolio from "../../../assets/img/radixportfolio.png";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import StakeTracker from "./StakeTracker";

function Body() {
  return (
   <div className="home-body">
      <div
        className="stake-trackers-wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          overflowX: 'hidden',
          overflowY: 'hidden'
        }}
      >
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
     
        <StakeTracker single={true} />
        <StakeTracker single={true} />
        <div
          style={{ display: "flex", flexWrap: "wrap" }}
          className="secondRow"
        >       
          <StakeTracker />
          <StakeTracker />
        </div>
      </div>
    </div>
  );
}

export default Body;
