import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/DashboardsComponents/Body";
import "../../assets/css/home.css";
// import DashboardHeader from "../components/DashboardHeader/DashboardHeader";

function Dashboards() {
  return (
    <div  className="Dashboards">
      <Header Dashboards={true} />
      <Body />
    </div>
  );
}

export default Dashboards;
