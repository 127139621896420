import React from "react";
import "../../../assets/css/StakeTracker.css";
import radixportfolio from "../../../assets/img/radixportfolio.png";

function StakeTracker({ single }) {
  return (
    <div className="StakeTracker">
      <div className="StakeTrackerDetails">
        <div className="headingTool">Tool:</div>
        <div className="headingTool">URL:</div>
        <div className="headingTool">Features:</div>
      </div>

      <div className="StakeTrackerHeading">
        <div className="headingTool">Radix Portfolio Tracker</div>
        <div className="headingTool">
          <a href="https://t.me/radixStakerDelegator" target="_blank">
            https://radixportfolio.info
          </a>
        </div>
        <div className="headingTool">
          Portfolio positon summary <br />
          Per Validator stake breakdown <br />
          Rewards earned reporting <br />
          Capital Gains Tax reporting <br />
          Staked Validator changes alerting
        </div>
      </div>

      <div className="StakeTrackerImage">
        <img style={{ objectFit: "cover" }} src={radixportfolio} alt="" />
        {single && (
          <>
            <img
              style={{ objectFit: "cover" }}
              src={radixportfolio}
              alt=""
              width="250"
              height="250"
            />
            <img
              style={{ objectFit: "cover" }}
              src={radixportfolio}
              alt=""
              width="250"
              height="250"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default StakeTracker;
