import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/StakeTrackersComponents/Body";
import "../../assets/css/home.css";

function StakeTrackers() {
  return (
    <div  className="StakeTrackers">
      <Header StakeTrackers={true} />
      <Body />
    </div>
  );
}

export default StakeTrackers;
