import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/NetworkExplorersComponents/Body";
import "../../assets/css/home.css";
// import DashboardHeader from "../components/DashboardHeader/DashboardHeader";

function NetworkExplorers() {
  return (
    <div  className="NetworkExplorers">
      <Header NetworkExplorers={true} />
      <Body />
    </div>
  );
}

export default NetworkExplorers;
