
import React, { Component, PropTypes, useState } from 'react'
import '../../../assets/css/Body.css'
import { Link } from 'react-router-dom';

function Body() {
 
  

  return (
    <div className="home-body">
     <table>
    <tbody>
    
         
    <tr style={{height:'30px'}}></tr>
    <tr style={{height:'30px'}}></tr>
    <tr style={{height:'30px'}}></tr>
         
         <tr style={{height:'30px'}}>
         <td ><div style={{backgroundColor:'white',height:'20px',width:'400px',textAlign:'left'}}>Column</div></td>
         <td style={{wordBreak: 'break-all',textAlign:'left'}}> %</td> 
         </tr>
    </tbody>
    </table>
  

 </div>

 
  );
}

export default Body; 



