import React from "react";
import "../../../assets/css/Header.css";
import RadixStakerPop from '../../../assets/img/radixstaker.png'
import RadixSmall from '../../../assets/img/small.png'
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";


function Header({Home, StakeTrackers, Dashboards, NetworkExplorers}) {
  const matches = useMediaQuery("(max-width: 600px)");

  return (
    
      <div className="header-bottom">
        <nav>
          <li className={`${Home?"active-link":""}`}>
         
            <Link to="/">
            Home
            </Link>
          </li>
          <li className={`${StakeTrackers?"active-link":""}`}>
          
            <Link to="/StakeTrackers">      
            StakeTrackers
            </Link>
           
          </li>
          <li className={`${Dashboards?"active-link":""}`}>
            <Link to="/Dashboards">
            Dashboards
            </Link>
          </li>
          <li className={`${NetworkExplorers?"active-link":""}`}>
            <Link to="/NetworkExplorers">
            NetworkExplorers
            </Link>
          </li>

         {
           (Home || StakeTrackers|| Dashboards || NetworkExplorers )?
           (
            <div className="logo">
            <img src={matches?RadixSmall:RadixStakerPop} width={matches?`80px`:'150px'} alt="" />
          </div>
           )
           :""
         }
        </nav>
      </div>
    
  );
}

export default Header;
