import React from "react";
import RadixStaker from "../../../assets/img/radixstaker.png";
import telegram from "../../../assets/img/telegram.png";

function Body() {
  return (
    <div className="home-body">
       <div><p>&nbsp;</p></div>
       <div><p>&nbsp;</p> </div>
       <div><p>&nbsp;</p></div>
       <div><p>&nbsp;</p> </div>
       <div className="home-body-plain"> 
        The much anticipated Radix {<a href="https://learn.radixdlt.com/article/what-is-being-delivered-for-the-olympia-mainnet" target="_blank">Mainnet</a>} is is finally here. RadixStaker is {<a href="https://learn.radixdlt.com/article/what-is-kycaml" target="_blank">KYC</a>} verified by Radix and is running a validator node to help secure the Radix ledger. You can help secure the Radix protocol by  {<a href="https://learn.radixdlt.com/article/how-do-token-holders-earn-and-claim-xrd-emissions-rewards" target="_blank">staking</a>} your Radix tokens with validators such as ours. 
        {<a href="https://exrv1qd0060jej9x0upkp6fdrednv8ct2yvdf2ydxm5a9t9ea63tp09c6u0ev6t8plorer.radixdlt.com/#/validators" target="_blank"> Validator</a>} selection requires careful consideration. Your choice will not only impact your share of the Radix protocol's emission {<a href="https://www.radixdlt.com/post/radix-staking-and-incentive-rewards-guide" target="_blank">rewards</a>},
        but a poor choice may also put the security of the overall network at risk. {<a href="https://learn.radixdlt.com/article/how-should-i-choose-validators-to-stake-to" target="_blank">Choose</a>} wisely! 
        </div>
        <div className="home-body-plain">Join our Telegram {<a href="https://t.me/radixStakerDelegator" target="_blank">group</a>} to get in touch.</div>
        
        <a href="https://t.me/radixStakerDelegator"  target="_blank">
      <div className="footer__discord cursor">
        <img src={telegram} alt="" />
      </div>
      
      </a>       
        <div className="home-body-presentation"> 
        <h1>Why stake with us?</h1>
       
        </div>
      </div>
    );
}

export default Body;
