import React from "react";
import Header from "../components/HomeComponents/Header";
import Body from "../components/HomeComponents/Body";
import "../../assets/css/home.css";
  
function Home() {
  return (
    <div  className="Home">
      <Header Home={true} />
      <Body />
    </div>
  );
}

export default Home;
